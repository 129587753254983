import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { textCompiler } from "../../../../services/textCompiler";
import { Image, HeaderTitle } from "../../../../components";
import { StarIcon } from "@heroicons/react/outline";

import { getStrapiMediaUrl } from "../../../../services/strapiApi";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import SliderDot from "../../../../components/Button/SliderDot";
import { CarrouselSectionType } from "../../../../types/strapi/CarrouselSectionType";
import { markdownToHtml } from "../../../../services/markdown";

interface CarrouselSectionProps extends CarrouselSectionType {}

export function CarrouselSection(props: CarrouselSectionProps): JSX.Element {
  const { title, subtitle, bgColor, ratings } = props;
  //console.log(bgColor);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  //console.log(title, "titl", subtitle, "sub");
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      origin: "center",
      perView: 2,
      spacing: 20,
    },
    breakpoints: {
      "(max-width: 800px)": {
        slides: {
          origin: 0,
          perView: 1,
          spacing: 20,
        },
      },
      "(max-width: 460px)": {
        slides: {
          origin: 0,
          perView: 1,
          spacing: 10,
        },
      },
    },
    created() {
      setLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  return (
    <Main className="max-w-[800px]">
      <Header>
        <HeaderTitle $as="h2">{textCompiler(title)}</HeaderTitle>
        <Subtitle>{textCompiler(subtitle)}</Subtitle>
      </Header>
      <div className="relative mt-20">
        <Backgroundlayer style={{ backgroundColor: bgColor }}></Backgroundlayer>
        <div ref={sliderRef} className="keen-slider">
          {ratings.map((rating, i) => (
            <CarousselElement
              key={`carousel-element-${i}`}
              className={`keen-slider__slide number-slide${i} max-w-auto`}
            >
              <Text className="font-extrabold text-base">{rating.title}</Text>
              <Text
                className={`prose break-words text-left flex flex-grow`}
                dangerouslySetInnerHTML={{
                  __html: markdownToHtml(rating.text),
                }}
              />
              <BlockContainer>
                {rating?.image?.data && (
                  <ImageContainer>
                    <ImageStyled
                      style={{ borderRadius: "9999px" }} // le rounded-full ne marche pas ?
                      src={getStrapiMediaUrl(rating.image.data.attributes)}
                      alt="card image"
                    />
                  </ImageContainer>
                )}
                <Author>
                  {rating.note
                    ? `${rating.author ? `${rating.author}` : ""}${
                        rating.type ? ` - ${rating.type} - ` : ""
                      }${rating.note} ⭐ `
                    : `${rating.author ? `${rating.author}` : ""}${
                        rating.type ? ` - ${rating.type}` : ""
                      }`}
                </Author>
              </BlockContainer>
              <BlockProductContainer>
                {rating?.produitimage?.data && (
                  <ImageContainerProduct>
                    <ImageStyledProduct
                      src={getStrapiMediaUrl(
                        rating.produitimage.data.attributes
                      )}
                      alt="product image"
                    />
                  </ImageContainerProduct>
                )}
              </BlockProductContainer>
            </CarousselElement>
          ))}
        </div>
      </div>
      <div className="w-full  z-50 mt-22 flex justify-center items-center">
        {loaded && instanceRef.current && (
          <Dots>
            {Array.from(Array(ratings.length).keys()).map(
              (current: any, i: number) => {
                const active: boolean = currentSlide === i;
                return (
                  <SliderDot
                    key={i}
                    color={bgColor}
                    active={active}
                    onclick={() => {
                      instanceRef.current?.moveToIdx(i);
                    }}
                  />
                );
              }
            )}
          </Dots>
        )}
      </div>
    </Main>
  );
}

const Main = tw.div`
  lg:mt-0
  lg:mx-0
  py-10
  px-4
  md:px-8
  relative
  bg-white
`;

const Backgroundlayer = tw.div`
	absolute 
	top-[50%]
	-translate-x-[50%]
	-translate-y-[50%]
	left-[50%]
	bg-[#0C8261]
  h-[120%]
	w-[75%]
	rounded
`;

const Header = tw.div`
  md:text-center
  mb-4
  select-none
`;

const Subtitle = tw.div`
  my-2
  text-base
  select-none
`;

const CarousselElement = tw.div`
	bg-white
	p-4
	rounded
	drop-shadow-md
	border
	border-gray
	flex flex-col justify-between
	cursor-grabbing
`;

const RatingsCardsContainer = tw.div`
  grid
  items-start
  justify-center
  w-full
  text-center
  select-none
  min-h-[6rem]
`;

const CarouselContainer = tw.div`
flex flex-row
`;

interface CarouselElementProps {
  $displayed: boolean;
}

const CarouselElement = tw.div<CarouselElementProps>`
${(props: CarouselElementProps) => (props.$displayed ? "block" : "hidden")}
`;

const Dots = tw.div`
	flex 
	items-center
	gap-2
`;

const Text = tw.div`
  text-sm
  leading-6
  font-semibold
  mb-6
  text-center
`;

const Author = tw.p`
  text-sm
  text-gray-600
  flex
`;

const AuthorContainer = tw.div`

`;

const ImageContainer = tw.div`
  max-w-[40px]
  min-w-[40px]
  mr-2
`;

const ImageContainerProduct = tw.div`
  max-w-[150px]
  min-w-[150px]
  py-2
  mr-2
`;

const ImageStyled = tw(Image)`
  rounded-full
`;

const ImageStyledProduct = tw(Image)`
max-w-fit
`;

const BlockContainer = tw.div`
flex
flex-row
flex-end
items-center
`;

const BlockProductContainer = tw.div`
  justify-center
  flex
  gap-2
  w-full
`;
