/* eslint-disable indent */
import tw from "tailwind-styled-components";
import { Button } from "../../../../components";
import { BackgroundColor } from "../../../../types/strapi/v2/common";
import { getBackgroundColor, getTextColor } from "./utils";
import { TextSection } from "./TextSection";

interface VideoSectionProps {
  video: {
    url: string;
    provider: string;
    providerUid: string;
  };
  title: string;
  content: string;
  cta: any;
  backgroundColor: BackgroundColor;
  fullVideo: boolean;
}

export function VideoSection(props: VideoSectionProps): JSX.Element {
  const { video, title, content, cta, backgroundColor, fullVideo } = props;
  const bgColor = getBackgroundColor(backgroundColor);
  const textColor = getTextColor(backgroundColor);

  return (
    <Main
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      {fullVideo ? (
        <div className="flex flex-col">
          <VideoContainer>
            <div>
              {title && content ? (
                <div className="lg:text-xl text-title font-bold ">{title}</div>
              ) : null}

              <div className="mt-10 space-x-3 items-center flex flex-row">
                <div>
                  {!content ? (
                    <TextSection
                      textColor={textColor}
                      title={title}
                      text={content}
                    />
                  ) : (
                    <TextSection textColor={textColor} text={content} />
                  )}
                </div>
              </div>
            </div>
          </VideoContainer>
          <div className="flex flex-col items-center">
            <div className="sm:hidden">
              <iframe width="385" height="220" src={video.url} />
            </div>
            <div className="hidden sm:flex">
              <iframe width="785" height="453" src={video.url} />
            </div>
            <div className="hidden mt-5 lg:flex">
              {cta ? (
                <ButtonContainer>
                  <ButtonStyled
                    styled="w-70"
                    color={cta.bgColor}
                    href={cta.destination}
                    target={cta.newWindow ? "_blank" : ""}
                    rel="noreferrer"
                  >
                    {cta.label}
                  </ButtonStyled>
                </ButtonContainer>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <VideoContainer>
          <div>
            {title && content ? (
              <div className="lg:text-xl text-title font-bold">{title}</div>
            ) : null}

            <div className="flex sm:hidden">
              {!content ? (
                <TextSection
                  textColor={textColor}
                  title={title}
                  text={content}
                />
              ) : (
                <TextSection textColor={textColor} text={content} />
              )}
            </div>

            <div className="flex flex-col-2 sm:mt-10 space-x-3 items-center justify-center">
              <div>
                <div className="hidden sm:flex">
                  {!content ? (
                    <TextSection
                      textColor={textColor}
                      title={title}
                      text={content}
                    />
                  ) : (
                    <TextSection textColor={textColor} text={content} />
                  )}
                </div>
                <div className="hidden lg:flex">
                  {cta ? (
                    <ButtonContainer>
                      <ButtonStyled
                        styled="w-70"
                        color={cta.bgColor}
                        href={cta.destination}
                        target={cta.newWindow ? "_blank" : ""}
                        rel="noreferrer"
                      >
                        {cta.label}
                      </ButtonStyled>
                    </ButtonContainer>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div className="sm:hidden">
                  <iframe width="385" height="220" src={video.url} />
                </div>
                <div className="hidden sm:block">
                  <iframe width="685" height="393" src={video.url} />
                </div>
              </div>
            </div>
          </div>
        </VideoContainer>
      )}

      <div className="lg:hidden">
        {cta ? (
          <ButtonContainer>
            <ButtonStyled
              styled="w-full"
              color={cta.bgColor}
              href={cta.destination}
              target={cta.newWindow ? "_blank" : ""}
              rel="noreferrer"
            >
              {cta.label}
            </ButtonStyled>
          </ButtonContainer>
        ) : null}
      </div>
    </Main>
  );
}

const Main = tw.div`
my-0
lm:px-0 
w-full
px-4
md:px-8
py-10
`;

const ButtonStyled = tw(Button)`
  mt-4
  mr-0
  lg:mt-0
`;

const ButtonContainer = tw.div`
  w-full
`;

const VideoContainer = tw.div`
  lg:gap-5`;
