import React from "react";
import tw from "tailwind-styled-components";
import { convertPxToRem } from "../../../../services/convertTo";
import { markdownToHtml } from "../../../../services/markdown";
import { getTextColorClass } from "./utils";

type TitleLevel = "h1" | "h2" | "h3";

interface TextSectionProps {
  title?: string;
  text?: any;
  titleSize?: number;
  textSize?: number;
  level?: TitleLevel;
  textColor: string;
}

export function TextSection(props: TextSectionProps): JSX.Element {
  const { title, text, titleSize, textSize, level, textColor } = props;

  return (
    <Main>
      <Title
        $as={level || "h2"}
        className="HeaderTitle"
        style={{
          color: textColor,
        }}
        $fontSize={titleSize}
      >
        {title}
      </Title>
      {text ? (
        <Text
          style={{
            fontSize: textSize && `${convertPxToRem(textSize)}rem`,
            color: textColor,
          }}
          dangerouslySetInnerHTML={{
            __html: markdownToHtml(text),
          }}
          className={`prose ${getTextColorClass(textColor)} break-words`}
        />
      ) : null}
    </Main>
  );
}

export const Main = tw.div`
mb-6 
`;

type TitleProps = {
  $fontSize?: number;
};

export const Title = tw.h1<TitleProps>`
  text-title
  lg:text-xl 
  font-bold
  ${(p) => (p.$fontSize ? `text-[${convertPxToRem(p.$fontSize)}rem]` : "")}
`;

const Text = tw.div`
  text-base
  my-4 
`;
