/* eslint-disable indent */
import tw from "tailwind-styled-components";
import { Image } from "../../../../components";
import { getStrapiMediaUrl } from "../../../../services/strapiApi";
import { StrapiMedia } from "../../../../types";

interface BannerImageSectionProps {
  destination?: string;
  image: {
    data: StrapiMedia;
  };
}

export function BannerImageSection(
  props: BannerImageSectionProps
): JSX.Element {
  const { image, destination } = props;

  return (
    <Main>
      {destination ? (
        <div>
          {image.data && (
            <StyledImg
              onClick={() => window.open(destination ? destination : "")}
              src={getStrapiMediaUrl(image.data.attributes)}
              alt={image.data.attributes.name}
            />
          )}
        </div>
      ) : (
        <div>
          {image.data && (
            <StyledImg
              src={getStrapiMediaUrl(image.data.attributes)}
              alt={image.data.attributes.name}
            />
          )}
        </div>
      )}
    </Main>
  );
}

const Main = tw.div`
my-0
w-full
px-0
py-10
text-left
flex
justify-center

`;

const StyledImg = tw(Image)`

`;
