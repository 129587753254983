import tw from "tailwind-styled-components";
import { Image, RealButton } from "../../../../components";
import { convertPxToRem } from "../../../../services/convertTo";
import { PageVariables } from "../../../../services/pageVariables";
import { getStrapiMediaUrl } from "../../../../services/strapiApi";
import { textCompiler } from "../../../../services/textCompiler";
import { StrapiMedia } from "../../../../types";
import { CTAType } from "../../../../types/strapi/CTAType";
import { markdownToHtml } from "../../../../services/markdown";
import { BackgroundColor } from "../../../../types/strapi/v2/common";
import { getBackgroundColor, getTextColor, getTextColorClass } from "./utils";

export type TextColor = "black" | "white";

interface TitleSectionProps {
  variables?: PageVariables;
  isFullScreen?: boolean;
  data: TitlesSectionType;
  hasbooker?: boolean;
}

export type TitlesSectionType = {
  title: string;
  content: string;
  backgroundColor: BackgroundColor;
  titleSize: number;
  textSize: number;
  image: { data: StrapiMedia };
  cta?: CTAType;
};

export function TitleSection(props: TitleSectionProps): JSX.Element {
  const { data, variables } = props;
  const title = textCompiler(data.title, variables);
  // const titleSize = data.titleSize
  //   ? `${convertPxToRem(data.titleSize)}rem`
  //   : "3.5rem";
  const textSize = data.textSize
    ? `${convertPxToRem(data.textSize)}rem`
    : "1.25rem";
  const bgColor = getBackgroundColor(data.backgroundColor);

  // console.log(data);

  const textColor = getTextColor(data.backgroundColor);

  return (
    <Main
      style={{
        //backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <ColText>
        <TitleTextCol>
          <Title
            // style={{
            //   fontSize: titleSize,
            // }}
            className={
              props.hasbooker
                ? "md:block hidden text-[2rem] sm:text-[3.5rem]"
                : "text-[2rem] sm:block sm:text-[3.5rem]"
            }
          >
            {title}
          </Title>

          <Text
            style={{
              fontSize: textSize,
            }}
            dangerouslySetInnerHTML={{
              __html: markdownToHtml(data.content),
            }}
            className={`prose ${getTextColorClass(textColor)} break-words`}
          />

          {data.cta && (
            <RealButton
              onClick={() =>
                window.open(
                  data.cta?.destination,
                  data.cta?.newWindow ? "_blank" : ""
                )
              }
              color={data.cta.bgColor}
              className="mt-12 w-full lg:w-70 mb-10"
            >
              {data.cta.label}
            </RealButton>
          )}
        </TitleTextCol>

        {data.image && data.image.data && (
          <TitleImageCol>
            <Image
              src={getStrapiMediaUrl(data.image.data.attributes)}
              alt="title"
            />
          </TitleImageCol>
        )}
      </ColText>
    </Main>
  );
}

const TitleImageCol = tw.div``;
const TitleTextCol = tw.div``;

const Main = tw.div`
  w-full
`;

const ColText = tw.div`
grid
content-center
py-12
pb-6
px-4
md:px-8
lg:flex
flex-row

`;

const Title = tw.h1`
font-bold
leading-[4rem]
mb-8
break-words
sm:hidden
`;

const Text = tw.div`
leading-8
`;
