/* eslint-disable indent */
import tw from "tailwind-styled-components";
import { Button, Image } from "../../../../components";
import { getStrapiMediaUrl } from "../../../../services/strapiApi";
import { StrapiMedia } from "../../../../types";
import { TextSection } from "./TextSection";
import { BackgroundColor } from "../../../../types/strapi/v2/common";
import { getBackgroundColor, getTextColor } from "./utils";

interface ImageSectionProps {
  imageLeft: boolean;
  image: {
    data: StrapiMedia;
  };
  title: string;
  content: string;
  cta: any;
  backgroundColor: BackgroundColor;
}

export function ImageSection(props: ImageSectionProps): JSX.Element {
  const { imageLeft, image, title, content, cta, backgroundColor } = props;
  const bgColor = getBackgroundColor(backgroundColor);

  const textColor = getTextColor(backgroundColor);

  return (
    <Main
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
      $isImageLeft={imageLeft || !image.data}
    >
      {image.data && (
        <LeftCol>
          <StyledImg
            src={getStrapiMediaUrl(image.data.attributes)}
            alt={image.data.attributes.name}
          />
        </LeftCol>
      )}

      <RightCol $fullWidth={image.data ? false : true}>
        <TextSection
          textColor={textColor}
          title={title}
          titleSize={50}
          text={content}
        />
        {cta && (
          <ButtonStyled
            styled="w-full lg:w-70"
            color={cta.bgColor}
            href={cta.destination}
            target={cta.newWindow ? "_blank" : ""}
            rel="noreferrer"
          >
            {cta.label}
          </ButtonStyled>
        )}
      </RightCol>
    </Main>
  );
}

interface MainProps {
  $isImageLeft?: boolean;
}

const Main = tw.div<MainProps>`
  my-0
  lm:px-0 
  w-full
  px-4
  md:px-8
  py-10
  items-center
  lg:flex
  ${(p) => (p.$isImageLeft ? "flex-row" : "flex-row-reverse")}
`;

const LeftCol = tw.div`
  basis-1/3
`;

const StyledImg = tw(Image)`
 
`;

type RightColProps = {
  $fullWidth?: boolean;
};

const RightCol = tw.div<RightColProps>`
  ${(d) => (d.$fullWidth ? "" : "basis-2/3")}
`;

const ButtonStyled = tw(Button)`
  mt-4
  lg:mt-0
`;
