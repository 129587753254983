/* eslint-disable indent */
import { GetStaticPropsContext } from "next";
import Image from "next/image";
import React, {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import tw from "tailwind-styled-components";
import { colord } from "colord";
import { getTransportPage } from "./vtc";
import { generatePageVariables } from "../services/pageVariables";
import { SeoHead } from "../containers/components";
import { StrapiApi } from "../services/strapiApi";
import { PlacePageParams } from "./vtc/[city]/[place]";
import { TransportationType } from "../services/transportationTypes";
import { getTransportationTypePrefooter } from "../services/bestCitiesByType";
import {
  FooterSectionType,
  GetStaticPath,
  NavigationBarType,
  PageBaseProps,
  PageSectionType,
  PreFooterSectionType,
  PreFooterSectionItem,
  TransportationTypeEnum,
} from "../types";
import { PageVariables } from "../services/pageVariables";
import { PageLayout } from "../components/Layout/PageLayout";
import { getFooterCache, getNavBarCache } from "../services/cache";
import {
  generatePreFooterSingleType,
  renderSection,
} from "../services/section";
import { ImageSection } from "../containers/components/v2/sections/ImageSection";
import {
  TitleSection,
  TitlesSectionType,
} from "../containers/components/v2/sections/TitleSection";
import { LandingPage as LandingPageType } from "../types/strapi/v2/pages";
import {
  ImageSection as ImageSectionType,
  Section as SectionType,
  CardsSection as CardsSectionType,
  BannerImageSection as BannerImageType,
  CarrouselSection as CarrouselType,
  BackgroundColor,
  VideoSection as VideoSectionType,
} from "../types/strapi/v2/common";
import { CardsSection } from "../containers/components/v2/sections/CardsSection";
import { IFrameBooker } from "../components/IFrame";
import { BannerImageSection } from "../containers/components/v2/sections/BannerImageSection";
import { CarrouselSection } from "../containers/components/v2/sections/CarrouselSection";
import { useScrollPosition } from "../hooks";
import { VideoSection } from "../containers/components/v2/sections/VideoSection";
import { useRouter } from "next/router";

export type NextPageProps = {
  navBarSection: PageSectionType<NavigationBarType>;
  footerSection: PageSectionType<FooterSectionType>;
  preFooterSection?: PageSectionType<PreFooterSectionType>;
  page: LandingPageType;
  preFooter?: PreFooterSectionItem[];
  variables?: PageVariables;
};

type SectionProps = {
  children: ReactNode;
};

function Section({ children }: SectionProps) {
  const scrollPosition = useScrollPosition();
  const [opacity, setOpacity] = useState(0);
  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    //console.log(scrollPosition, "changed");
    if (!window) {
      return;
    }
    if (opacity) {
      return;
    }
    if (!ref.current) {
      return;
    }

    const bottomPosition = window.scrollY + window.innerHeight;
    if (ref.current.offsetTop < bottomPosition) {
      setOpacity(1);
    }
  }, [scrollPosition]);

  return (
    <div
      ref={ref}
      className="transition ease-in-out duration-700 delay-150"
      style={{ opacity: opacity }}
    >
      {children}
    </div>
  );
}

export default function NextPage(props: NextPageProps): JSX.Element {
  //console.log(props);

  const [titleHeight, setTitleHeight] = useState(0);
  const titleSection = React.createRef<HTMLDivElement>();

  const router = useRouter();
  const { locale } = router;

  function updateTitleHeight() {
    console.log("updateTitleHeight");
    if (!window) {
      console.log("updateTitleHeight no window");
      return;
    }
    const windowHeight = window.innerHeight;
    if (!titleSection.current) {
      console.log("updateTitleHeight no title");
      setTitleHeight(windowHeight);
    } else {
      //console.log(titleSection.current.getBoundingClientRect().bottom);
      //console.log(titleSection.current.getBoundingClientRect().y);
      //console.log(titleSection.current.getBoundingClientRect().height);
      const titleBottom = titleSection.current.getBoundingClientRect().bottom;
      //console.log(titleBottom);
      if (windowHeight < titleBottom) {
        setTitleHeight(titleBottom);
      } else {
        setTitleHeight(windowHeight);
      }
    }
  }

  useEffect(updateTitleHeight, [titleHeight]);

  const { page } = props;
  const {
    title,
    content,
    backgroundColor,
    cta,
    banner,
    seo,
    hasBooker,
    sections,
  } = page;

  const titleContent: TitlesSectionType = {
    title: title,
    content: content,
    backgroundColor: backgroundColor,
    textSize: 20,
    titleSize: 56,
    cta: cta
      ? {
          bgColor: "#fc03e8",
          destination: cta.destination,
          label: cta.label,
          newWindow: cta.newWindow,
        }
      : undefined,
    image: banner,
  };

  function getBackgroundColor(color: BackgroundColor) {
    if (color === "green") {
      return "#0D8261";
    }
    if (color === "grey") {
      return "#E5E9EB";
    }
    if (color === "black") {
      return "#000000";
    }
    if (color === "lightGrey") {
      return "#f2f2f2";
    }
    return "#FFFFFF";
  }

  const headerBackroundColor = getBackgroundColor(titleContent.backgroundColor);

  return (
    <>
      <SeoHead title={seo.title} description={seo.description} />
      <PageLayout>
        <div className="sticky top-0 z-50 bg-white">
          {renderSection(props.navBarSection, undefined, false)}
        </div>

        <div
          className="absolute w-full z-0"
          style={{
            height: titleHeight,
            background: `linear-gradient(166deg, ${colord(headerBackroundColor)
              .darken(0.1)
              .toHex()} 0%, ${headerBackroundColor} 38%, ${colord(
              headerBackroundColor
            )
              .lighten(0.1)
              .toHex()} 100%)`,
          }}
        ></div>

        <Container className="relative z-10 max-w-screen-2xl mx-auto">
          {hasBooker ? (
            <LeftCol>
              <div className="py-4 pb-2 px-4 bg-white md:hidden">
                <h1 className="font-bold mb-4 break-words text-title">
                  {titleContent.title}
                </h1>
              </div>
              <IFrameBooker
                hasBooker={true}
                reassurances={
                  /(^|\/)en(\/|$)/.test(router.asPath)
                    ? [
                        "Drivers rated 4,8/5",
                        "Fixed price in advance",
                        "Free advance booking",
                        "Free cancellation",
                      ]
                    : [
                        "Chauffeurs 4,8/5",
                        "Tarif fixé à l’avance",
                        "Reservation sans frais a l'avance",
                        "L'annulation est gratuite",
                      ]
                }
              />
            </LeftCol>
          ) : null}

          <div className={hasBooker ? "basis-2/3" : undefined}>
            <Section key="Header-Section">
              <div
                // className={hasBooker ? "md:block hidden" : ""}
                ref={titleSection}
              >
                <TitleSection hasbooker={hasBooker} data={titleContent} />
              </div>
            </Section>
            <Content>
              {sections.map((section: SectionType, index: number) => {
                if (section.__component === "sections.image-banner") {
                  return (
                    <Section key={`Session-${section.__component}-${index}`}>
                      <div
                        style={{
                          backgroundColor: getBackgroundColor(
                            (section as ImageSectionType).backgroundColor
                          ),
                        }}
                      >
                        <div>
                          <ImageSection
                            key={`Section-${section.id}`}
                            {...(section as ImageSectionType)}
                          />
                        </div>
                      </div>
                    </Section>
                  );
                } else if (section.__component === "sections.cards-section") {
                  return (
                    <Section key={`Session-${section.__component}-${index}`}>
                      <CardsSection
                        key={`Section-${section.id}`}
                        {...(section as CardsSectionType)}
                      />
                    </Section>
                  );
                } else if (
                  section.__component === "sections.banner-image-section"
                ) {
                  return (
                    <Section key={`Session-${section.__component}-${index}`}>
                      <BannerImageSection
                        key={`Section-${section.id}`}
                        {...(section as BannerImageType)}
                      />
                    </Section>
                  );
                } else if (
                  section.__component === "sections.carrousel-section"
                ) {
                  return (
                    <Section key={`Session-${section.__component}-${index}`}>
                      <div className="lg:justify-center lg:flex">
                        <CarrouselSection
                          key={`Section-${section.id}`}
                          {...(section as CarrouselType)}
                        />
                      </div>
                    </Section>
                  );
                } else if (section.__component === "sections.video-section") {
                  return (
                    <Section key={`Session-${section.__component}-${index}`}>
                      <div className="lg:justify-center lg:flex">
                        <VideoSection
                          key={`Section-${section.id}`}
                          {...(section as VideoSectionType)}
                        />
                      </div>
                    </Section>
                  );
                } else {
                  return <></>;
                }
              })}
            </Content>
          </div>
        </Container>
        {props.preFooter
          ? renderSection(
              generatePreFooterSingleType(props.preFooter),
              props.variables
            )
          : null}
        {renderSection(props.footerSection, undefined, false)}
      </PageLayout>
    </>
  );
}

function extractSlug(slug: string | string[] | undefined): string {
  if (!slug) {
    return "";
  }
  if (typeof slug === "string") {
    return slug;
  }
  return slug.join("/");
}

export async function getStaticProps(
  context: GetStaticPropsContext
): Promise<PageBaseProps> {
  const { params, draftMode, locale } = context;
  //console.log(params);
  const slug: string = extractSlug(params?.slug);
  console.log({ slug });
  const type = "vtc";
  const pageTransport = await getTransportPage(
    type as TransportationTypeEnum,
    locale ?? "fr"
  );
  const variables = generatePageVariables(
    Object.assign({ type }, params) as PlacePageParams
  );

  const page = await StrapiApi.collection_types.landingPage(slug, draftMode);
  //console.log(page);
  if (!page) {
    return {
      notFound: true,
      revalidate: Number(process.env.NEXT_PUBLIC_REVALIDATE_TIME),
    };
  }

  const preFooter = getTransportationTypePrefooter(type as TransportationType);

  const navBarSection = await getNavBarCache();
  const footerSection = await getFooterCache();
  return {
    props: { page, navBarSection, footerSection, preFooter, variables },
    revalidate: Number(process.env.NEXT_PUBLIC_REVALIDATE_TIME),
  };
}

export async function getStaticPaths(): Promise<GetStaticPath> {
  const landingPages = await StrapiApi.collection_types.allLandingPagesSlugs(
    []
  );

  //console.log(landingPages);

  return {
    paths: landingPages.map((lp) => {
      if (lp.slug && lp.slug.startsWith("/")) {
        return lp.slug;
      } else {
        return `/${lp.slug}`;
      }
    }),
    fallback: false,
  };
}

const Content = tw.div``;

const Container = tw.div`
  lg:flex
  flex-row
`;

const LeftCol = tw.div`
  basis-1/3
`;
// const RightCol = tw.div`
// basis-2/3
// `;
