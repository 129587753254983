import { BackgroundColor } from "../../../../types/strapi/v2/common";

export function getBackgroundColor(color: BackgroundColor) {
  if (color === "green") {
    return "#0D8261";
  }
  if (color === "grey") {
    return "#E5E9EB";
  }
  if (color === "black") {
    return "#000000";
  }
  if (color === "lightGrey") {
    return "#f2f2f2";
  }
  if (color === "orange") {
    return "#ff8400";
  }
  return "#FFFFFF";
}

export function getTextColor(color: BackgroundColor) {
  if (color === "green") {
    return "#FFFFFF";
  }
  if (color === "black") {
    return "#FFFFFF";
  }
  if (color === "orange") {
    return "#FFFFFF";
  }
  return "#000000";
}

export function getTextColorClass(color: string) {
  if (color === "#000000") {
    return "";
  }
  return "prose-invert";
}
